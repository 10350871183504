<template>
  <div class="mobile">
    <div class="head">
      <div class="left">
        <img
          style="width: 36px; margin-left: 10px"
          src="../images/pc/logo.svg"
          alt=""
        />
      </div>
      <div class="right"><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">登 录</a></div>
    </div>
    <div class="swipers">
      <img src="../images/mobie/PbQvQ-GJE3P0IDFE.jpg" alt="" />
    </div>
    <div class="accout">
      <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">现在注册</a>
    </div>
    <div class="accout serves">
      <a
        href="https://q89ojjv4jh.vgukfwpv.com/5e449c3e9434995c005cjkfle-kelie4454097c404c1ec830ef9474465855fabb3bec958d7de50ea182049788bff76"
        >联系客服</a
      >
    </div>
    <div class="twoSoccer">
      <div class="sonwbox">
        <p>您的赛场由您掌控</p>
        <div class="soccerlog">
          <div class="imgBo">
            <router-link to="/mobieleft">
              <a href=""
                ><img
                  style="width: 150px"
                  src="../images/pc/Joint_wo-FBS-vertical-FBS_Lleicester-zh-Positive.svg"
                  alt=""
              /></a>
            </router-link>
          </div>
          <div class="imgBo">
            <router-link to="/mobieright">
              <img
                style="width: 150px"
                src="../images/pc/cn_logo-without-vertical-color-black.svg"
                alt=""
            /></router-link>
          </div>
        </div>
      </div>
      <div class="contact">
        <h3>FBS 社交网络</h3>
        <div>
          <a href=""
            ><img
              style="width: 30px"
              src="../images/pc/8b86e8c5c83a4784aaaff03aa83bb92f.jpeg"
              alt=""
          /></a>
        </div>
        <h3>联系我们</h3>
        <div>
          <a href=""
            ><img
              style="width: 30px"
              src="../images/pc/8b86e8c5c83a4784aaaff03aa83bb92f.jpeg"
              alt=""
          /></a>
          <a style="margin-left: 10px" href=""
            ><img style="width: 30px" src="../images/pc/wechat.jpeg" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div class="ending">
      <p>
        注册办公地址：HILLSIDE（GAMING）LP（注册编号120），UNIT 1.1, FIRST
        FLOOR, WATERPORT PLACE, 2 EUROPORT AVENUE, GIBRALTAR。
      </p>
      <p>
        HILLSIDE（GAMING）LP是由直布罗陀政府颁发执照并受直布罗陀博彩委员会监管（RGL编号077）。
      </p>
      <p>
        通过进入、继续使用或浏览此网站，您即被认定接受：我们将使用特定的浏览器COOKIES优化您的客户享用体验。365Asia仅会使用优化您服务体验的COOKIES，而不是侵犯您隐私的COOKIES。
      </p>
      <p>
        关于我们使用COOKIES，以及您如何取消、管理COOKIES使用的更多详情，请参考我们的COOKIES政策。
      </p>
      <p style="text-align: center">© 2001-2024 bet365版权所有</p>
    </div>
    <HelpMoble />
  </div>
</template>

<script>
import HelpMoble from "../components/helpMobie.vue";

export default {
  components: {
    HelpMoble,
  },
};
</script>

<style  scoped>
.mobile {
  background-color: #384147;
}
.head {
  height: 50px;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right {
  height: 50px;
  width: 80px;
  background-color: #507500;
  margin-right: 10px;
  line-height: 50px;
  text-align: center;
}
.swipers > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.accout {
  width: 100vw;
  height: 80px;
  background-color: #f04a00;
  position: relative;
  top: -10px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
}
.serves {
  background-color: #384147;
}
.twoSoccer {
  background-color: #000;
  top: -30px;
  position: relative;
}
.sonwbox {
  width: 90vw;
  background-color: #daeeed;
  margin: 0 auto;
  border-radius: 15px;
}
.sonwbox > p {
  color: #000;
  font-weight: 600;
  text-align: center;
}
.soccerlog {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.contact {
  color: #869ca8;
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
}
.ending {
  width: 100vw;
  background-color: #384147;
  font-size: 12px;
  color: #6b6b6b;
  position: relative;
  top: -10px;
}
.ending > p {
  margin: 0 auto;
  width: 95%;
}
</style>