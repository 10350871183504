<template>
  <div>
    <div class="help el-icon-search">
      <a
        href="https://q89ojjv4jh.vgukfwpv.com/5e449c3e9434995c005cjkfle-kelie4454097c404c1ec830ef9474465855fabb3bec958d7de50ea182049788bff76"
        >帮助
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.help {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 999;
  width: 100px;
  height: 50px;
  line-height: 50px;
  background-color: #00be40;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
</style>